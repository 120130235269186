<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="md"
  >
    <div class="grid grid-cols-1 gap-2.5 sm:gap-5 justify-center">
      <ui-typography
        variant="h3"
        tag="h3"
        class="text-center"
        text="Crop Your Photo"
      />
      <div>
        <ui-button
          text="Back To Default Avatars"
          variant="secondary-outline"
          icon-left="arrow_back"
          size="sm"
          @click="$emit('back')"
        />
      </div>
      <ui-loader
        static
        :loading="loading"
      >
        <ui-fields-file-image-cropper
          v-if="originalFile"
          :file="originalFile"
          :upload="onUpload"
          :avatar-properties="avatarProperties"
          class="w-full mx-auto"
          @success="$emit('uploaded', $event)"
        />
      </ui-loader>
    </div>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  const props = defineProps<CropImageProps>();

  const emit = defineEmits(['update:modelValue', 'back', 'uploaded']);
  const apiRoutes = useApiRoutes();
  createModalState(emit);

  const originalFile = ref<File>();
  const { handler, loading } = useHandlerErrorAndLoadingClient();

  watch(
    () => props.file,
    async file => {
      if (isString(file)) {
        const { isError, data } = await handler(getFileFromUrl(file));
        if (!isError) {
          originalFile.value = data;
        }
        return;
      }
      originalFile.value = file;
    },
    {
      immediate: true
    }
  );

  const onUpload = async (data: CropImageData) => {
    return await apiRoutes.profile.update({
      avatar: data.file,
      profile: {
        avatar_properties: data.avatar_properties
      },
      original_avatar: originalFile.value
    });
  };
</script>
