const dataUrlToFile = (dataURI: string, filename: string, baseMime?: string) => {
  const arr = dataURI.split(',');
  const mime = baseMime || arr[0].match(/:(.*?);/)?.[1];
  const byteString = window.atob(arr[1]);
  const u8arr = new Uint8Array(byteString.length).map((_, i) => byteString.charCodeAt(i));

  return new File([u8arr], filename, { type: mime });
};

export default dataUrlToFile;
