<template>
  <div class="relative">
    <ui-loader
      static
      :loading="loadingImage"
      static-slot
    >
      <Cropper
        ref="cropperRef"
        :src="fileLink"
        :stencil-props="stencilProps"
        :default-position="defaultPosition"
        :default-size="defaultSize"
        class="rounded-lg overflow-hidden mb-4 w-full"
        :default-transforms="defaultTransforms"
        :resize-image="{ wheel: false }"
        @change="onUpdateSize"
        @ready="onSetLoadingImage"
        @error="onSetLoadingImage"
      />
    </ui-loader>
    <div class="flex gap-1 mb-7 justify-center">
      <ui-button
        v-for="(action, i) in actions"
        :key="i"
        :icon-left="action.icon"
        :icon-class-left="action?.icon_classes"
        size="md-icon"
        variant="secondary-outline"
        @click="action.onClick"
      />
    </div>
    <ui-button
      full
      text="Update"
      :loading="loading || pending"
      @click="onCrop"
    />
  </div>
</template>

<script setup lang="ts">
  import { Cropper } from 'vue-advanced-cropper';
  import { onKeyStroke } from '@vueuse/core';
  import 'vue-advanced-cropper/dist/style.css';

  type Props = {
    file: File | string;
    upload?: (data: any) => void;
    avatarProperties?: AvatarProperties;
    loading?: boolean;
  };

  const emit = defineEmits(['crop', 'success', 'ready']);
  const props = withDefaults(defineProps<Props>(), {});

  const loadingImage = ref(true);

  const { handler, loading: pending } = useHandlerErrorAndLoadingClient({
    handlerOptions: { showServerError: true }
  });
  const cropperRef = ref();
  const cropStorage = reactive({
    coordinates: {
      width: 0,
      height: 0,
      left: 0,
      top: 0
    },
    imageTransforms: {
      rotate: 0,
      flip: {
        horizontal: false,
        vertical: false
      }
    }
  });
  const moveDirections = {
    left: () => cropperRef.value.move(-cropStorage.coordinates.width / 4),
    right: () => cropperRef.value.move(cropStorage.coordinates.width / 4),
    top: () => cropperRef.value.move(0, -cropStorage.coordinates.height / 4),
    bottom: () => cropperRef.value.move(0, cropStorage.coordinates.height / 4)
  };

  const defaultPosition = computed(() => {
    if (props.avatarProperties?.coordinates) {
      return {
        left: props.avatarProperties.coordinates.left,
        top: props.avatarProperties.coordinates.top
      };
    }

    return null;
  });

  const defaultSize = computed(() => {
    if (props.avatarProperties?.coordinates) {
      return {
        width: props.avatarProperties?.coordinates.width,
        height: props.avatarProperties?.coordinates.height
      };
    }

    return null;
  });

  const stencilProps = {
    aspectRatio: 1,
    previewClass: 'border-primary border-dashed border-2'
  };

  const fileData = computed(() => {
    if (isString(props.file)) {
      return {
        name: getFileNameByPath(props.file),
        type: getFileTypeByName(props.file)
      };
    }
    return {
      name: props.file.name,
      type: props.file.type
    };
  });

  const fileLink = computedAsync(async () => {
    if (isString(props.file)) {
      // TODO: change to origin CORS
      const url = new URL(props.file);
      return url.origin === window.location.origin ? props.file : '/api' + url.pathname;
    }
    return await generatePreviewLink(props.file);
  }, '');

  const actions = [
    { icon: 'zoom_in', onClick: () => onZoom(1.5) },
    { icon: 'zoom_out', onClick: () => onZoom(0.5) },
    { icon: 'flip', onClick: () => onFlip(true, false) },
    {
      icon: 'flip',
      onClick: () => onFlip(false, true),
      icon_classes: 'rotate-90'
    },
    { icon: 'rotate_left', onClick: () => onRotate(-90) },
    { icon: 'rotate_right', onClick: () => onRotate(90) }
  ];

  const defaultTransforms = () => {
    if (props.avatarProperties?.imageTransforms) {
      return {
        flip: {
          vertical: +props.avatarProperties?.imageTransforms.flip.vertical,
          horizontal: +props.avatarProperties?.imageTransforms.flip.horizontal
        },
        rotate: +props.avatarProperties?.imageTransforms.rotate
      };
    }

    return cropStorage.imageTransforms;
  };
  const onZoom = (factor: number) => {
    cropperRef.value.zoom(factor);
  };

  const onRotate = (angle: number) => {
    cropperRef.value.rotate(angle);
  };

  const onFlip = (x: boolean, y: boolean) => {
    cropperRef.value.flip(x, y);
  };

  const onMove = (direction: keyof typeof moveDirections) => moveDirections[direction]();

  const onUpdateSize = (result: AvatarProperties) => {
    const coordinates = result.coordinates;
    cropStorage.coordinates.width = Math.round(coordinates.width);
    cropStorage.coordinates.height = Math.round(coordinates.height);
  };

  const fileUpload = async (form: Keyable) => {
    const { isError, data } = await handler(async () => props.upload?.(form));
    if (!isError) {
      emit('success', data);
    }
  };

  const onCrop = () => {
    const croppedResults = cropperRef.value.getResult();
    const coordinates = croppedResults.coordinates;
    const imageTransforms = croppedResults.imageTransforms;

    const cropImageData = {
      file: dataUrlToFile(
        croppedResults.canvas.toDataURL(),
        fileData.value.name || 'test.png',
        fileData.value.type || 'image/png'
      ),
      avatar_properties: {
        coordinates,
        imageTransforms
      }
    };

    if (props.upload) {
      fileUpload(cropImageData);
    }
    emit('crop', cropImageData);
  };

  const onSetLoadingImage = (val = false) => {
    loadingImage.value = val;
  };

  onKeyStroke('ArrowUp', () => onMove('top'));
  onKeyStroke('ArrowDown', () => onMove('bottom'));
  onKeyStroke('ArrowLeft', () => onMove('left'));
  onKeyStroke('ArrowRight', () => onMove('right'));
</script>
