<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="md"
  >
    <div class="grid grid-cols-1 gap-2.5 sm:gap-5 justify-center">
      <ui-typography
        variant="h3"
        tag="h3"
        class="text-center"
        text="Crop Room Avatar"
      />
      <ui-loader
        static
        :loading="loading"
      >
        <ui-fields-file-image-cropper
          v-if="originalFile"
          :file="originalFile"
          class="w-full mx-auto"
          @crop="onUpload"
        />
      </ui-loader>
    </div>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  const props = defineProps<CropImageProps>();

  const emit = defineEmits(['update:modelValue', 'upload']);
  createModalState(emit);

  const originalFile = ref<File>();
  const { handler, loading } = useHandlerErrorAndLoadingClient();

  watch(
    () => props.file,
    async (file: string | File) => {
      if (isString(file)) {
        const { isError, data } = await handler(getFileFromUrl(file));
        if (!isError) {
          originalFile.value = data;
        }
        return;
      }
      originalFile.value = file;
    },
    {
      immediate: true
    }
  );

  const onUpload = async (data: CropImageData) => {
    emit('upload', data.file);
  };
</script>
